import { TeamSwitcher } from '@askable/ui/components/team-switcher';
import { Avatar, AvatarFallback } from '@askable/ui/core/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { supportedLanguages } from '@askableorg/i18n';
import { useFeatureFlags } from 'feature-flags';
import { Languages } from 'lucide-react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { useViewerContext } from '@/context/ViewerContext/ViewerContext';
import { useIsKinde } from '@/hooks/useIsKinde/useIsKinde';

import type { Teams } from '@/generated/graphql';
import type { Language } from '@askableorg/i18n';

export const UserMenu = () => {
  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang: Language) => {
    i18n.changeLanguage(lang);
    if (typeof window === 'undefined') {
      return;
    }
    // Save to local storage
    window.localStorage.setItem('lang', lang);
  };

  const { details: viewer } = useViewerContext();
  const isKinde = useIsKinde();

  const { TRANSLATIONS } = useFeatureFlags(['TRANSLATIONS']);

  const handleChange = async (id: string) => {
    const selectedTeam = viewer?.Teams?.find(team => team?._id === id);

    if (!selectedTeam?.kinde_org_id) {
      toast.error(`${selectedTeam?.name ?? 'The selected team'} has not been migrated. Please contact support.`);
      return;
    }

    const params = new URLSearchParams();
    params.append('org_code', selectedTeam?.kinde_org_id!);
    params.append('post_login_redirect_url', `${window.location.origin}/${window.location.pathname?.split('/')[1]}`);
    window.location.replace(`${window.location.origin}/api/auth/login?${params.toString()}`);
  };

  const isResearcher = viewer?.type?.researcher;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="cursor-pointer">
        <Avatar>
          <AvatarFallback>{viewer?.meta?.identity?.firstname?.[0] ?? 'U'}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" side="bottom" align="end">
        {isKinde && !isResearcher && (
          <>
            {viewer?.ConnectedTeam?.name ? <DropdownMenuLabel>{viewer?.ConnectedTeam?.name}</DropdownMenuLabel> : null}
            <TeamSwitcher
              handleSwitchTeams={handleChange}
              activeOrgId={viewer?.ConnectedTeam?._id}
              teams={viewer?.Teams as Pick<Teams, '_id' | 'name'>[]}
              hideSignIntoAnotherTeam
            />
          </>
        )}
        {TRANSLATIONS ? (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="gap-2">
                <Languages className="h-4 w-4" />
                <span>{supportedLanguages.find(l => l.code === i18n.language)?.label}</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {supportedLanguages.map(lang => (
                    <DropdownMenuItem
                      className={i18n.language === lang.code ? 'font-bold' : undefined}
                      key={lang.code}
                      onClick={() => changeLanguageHandler(lang.code)}
                    >
                      {lang.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </>
        ) : null}
        <DropdownMenuSeparator />
        <SignOutDropdownMenuItem isKinde={isKinde} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const SignOutDropdownMenuItem = ({ isKinde }: { isKinde: boolean }) => {
  const { t } = useTranslation();

  const signOutText = t('global.signOut');

  if (isKinde) {
    return (
      <DropdownMenuItem asChild>
        <Link href="/api/auth/logout">{signOutText}</Link>
      </DropdownMenuItem>
    );
  }

  return (
    <DropdownMenuItem
      asChild
      onClick={() => {
        if (typeof window === 'undefined') {
          return;
        }

        window.localStorage.clear();
      }}
    >
      <span>{signOutText}</span>
    </DropdownMenuItem>
  );
};
