'use client';

import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { HelpCircle, MessageCircleWarning, Heart } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { HubbleSurveys } from 'shared-utils/hooks/useHubble';

import { useHubbleWrapper } from '@/hooks/useHubbleWrapper/useHubbleWrapper';
import { useSegment } from '@/hooks/useSegment/useSegment';

export const HelpMenu = () => {
  const hubble = useHubbleWrapper();
  const { trackEvent } = useSegment();
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="!hidden sm:!flex">
              <HelpCircle className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipContent>{t('global.getHelp')}</TooltipContent>
      </Tooltip>

      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={() => {
            hubble.show({ survey: HubbleSurveys.HighlightsAndInsightsEarlyAccess });
            trackEvent('click_insight_help_menu_feedback');
          }}
        >
          <Heart className="h-4 w-4" />
          {t('global.giveFeedback')}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            window.Intercom('showMessages');
            trackEvent('click_insight_help_menu_support');
          }}
        >
          <MessageCircleWarning className="h-4 w-4" />
          {t('global.contactSupport')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
